<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Associations table</h3>

	<p>This table lists all associations in the framework. You may find it useful for getting an overview of the breadth of associations included in a framework. You can also export associations to a spreadsheet from here.</p>
	<img alt="Associations table example image" srcset="/docimages/tableassociationsview-1.png 2x" class="k-help-img">
	<p><img alt="Kebab menu image" srcset="/docimages/kebab_menu-1.png 4x" class="k-help-img float-right">To view the associations table, click the <span v-html="link('kebab_menu', '“kebab” menu')"></span> in the upper-right corner of the window and click TABLE from the Viewer Mode toggle button. Then click ASSOCIATIONS on the toggle button just below the kebab menu.<img alt="Table viewer mode example image" srcset="/docimages/tableitemsview-1.png 3x" class="k-help-img block"></p>
	<p><b>NOTE:</b> table views are not available in browser windows less than 960 pixels wide.</p>
	<ul>
		<li>Click “SHOW FILTERS” to see options for which association types to show in table rows, and which item fields to show in table columns. (Note that by default, “Is Child Of” associations, which define the “tree hierarchy” of a framework, are hidden.)</li>
		<li>Click column headings to sort the table, and enter text in the “Search” box to limit to rows matching the search text.</li>
		<li>Origin and Destination Items whose full statement are shown on a non-white background color are from the current framework (the background color indicates the item type, as in the tree and tile views); items with a white background and a dark border are from a different framework.<ul><li>For example, in the screenshot above, the first association indicates that MGSE9-12.A.SSE.4 from a different framework is Related To PC.PAR.7 from the current framework; the second association indicates that PC.PAR.7 Is Child Of 27.08410, with both items in the current framework.</li></ul></li>
	</ul>
	<ul>
		<li>Click an Origin or Destination Item’s full statement to show the item’s expanded tile.<ul><li>Or (for items that are from the current framework) hold down the COMMAND (Mac)/ALT (Windows) key while clicking the item to reveal the item in the Tree View.</li></ul></li>
		<li>Click the <b><v-icon small class="mr-1">fas fa-file-arrow-down</v-icon>EXPORT button</b> to download the data currently showing to a spreadsheet (in CSV format). The downloaded spreadsheet:<ul>
			<li>will include columns for each of the currently-showing fields (as selected via the SHOW FILTERS options),
			<li>and will include rows for each currently-visible association (i.e., association types currently hidden via the SHOW FILTERS options will not be exported; also, if you’ve entered terms in the search box, only associations that match those terms will be exported).</li>
		</ul></li>
		<li>Click the <v-icon small>fas fa-tree</v-icon> button in the top-right corner of the window to return to the default <span v-html="link('treeview', '“tree view”')"></span>.</li>
	</ul>
</div></template>

<script>
import HelpMixin from './HelpMixin'
export default {
	mixins: [HelpMixin],
}
</script>

<style lang="scss">
</style>
